<template>
  <div class="container">
    <img @click="toIndex" class="loginPic" src="@/assets/img/logo.png" alt="">
    <div class="form">
      <div class="right">
        <div class="loginimg">
          <img class="scaner" :src="scaner" />

          <img class="qrcodebg" @click="loginTab" :src="qrscaner" />
        </div>
        <span
          class="lf"
          v-if="status !== 'saologin'"
          :class="{ '': active, green: hasError }"
          @click="mesLogin"
          >短信登录</span
        >
        <span
          class="rt"
          v-if="status !== 'saologin'"
          :class="{ green: active, '': hasError }"
          @click="keyLogin"
          >密码登录</span>
        <span class="green" v-if="status == 'saologin'">手机扫码，安全登录</span>

        <div class="content" v-if="status === 'pwdlogin'">
          <div class="item">
            <span class="svg-container login-user"></span>
            <input type="text" placeholder="请输入账号" v-model="userNumber" />
          </div>
          <div class="item">
            <span class="svg-container login-password"></span>
            <input style="padding-left:10px" type="password" placeholder="请输入密码" @keydown="keyLog" v-model="userNumberPassword" />
          </div>
        </div>
        <div class="content" v-if="status === 'msglogin'">
          <div class="item">
            <span class="svg-container login-user"></span>
            <input type="text" placeholder="请输入手机号" v-model="userPhone" />
          </div>
          <div class="item">
            <span class="svg-container login-password"></span>
            <input placeholder="请输入验证码" v-model="userGetSlit" @keydown="keyLog"/>
          </div>
          <span class="getMsg" v-show="show" @click="getCode">获取验证码</span>
          <span v-show="!show" class="getMsg">{{ count }} s</span>
        </div>
        <div class="content" v-if="status === 'saologin'">
          <div class="qrCode">
              <canvas ref="can" id="canvas" class="canv"></canvas>
          </div>
          <div class="timeCode">{{timeMScoen+'s'}}</div>
        </div>
        <div
          v-if="status === 'saologin'"
          style="height: 33px; width: 85%; padding-left: 7%; margin-top: 12px"
        >
          <img class="saoimg" src="../../assets/img/sao.png" /><span class="wen"
            >打开手机<font style="color: #097638">通辽市英国英语语言学校</font>扫一扫登录</span
          >
        </div>
        <p class="wei" v-if="status !== 'saologin'">
          未注册手机号，完成验证即可注册并登录
        </p>
        <button class="btn" v-if="status !== 'saologin'" @click="login">
          登录
        </button>

        <span class="lfa" @click="forgetCode">{{ lftext }}</span>
        <span class="rta" @click="register">立即注册</span>
      </div>
    </div>
    
    <common-footer></common-footer>
  </div>
</template>

<script>
import { messageLogin,accountLogin,sendCode,getSalt,scanToken,scanLogin } from '@/common/public.js'
import MD5 from '@/common/md5.js'
import QRCode from 'qrcode'
import commonFooter from '@/components/loginFooter'
export default {
  name: "login",
  components: { commonFooter },
  data() {
    return {
      lftext: "忘记密码？",
      scaner: require("../../assets/img/scaner.png"),
      qrscaner: require("../../assets/img/qrcodebg.png"),
      active: true,
      show: true,
      count: "",
      timer: null,
      hasError: false,
      timers:null,//控制定时器
      myTimer:null,//控制定时器
      status: "pwdlogin",
      // timer:'',//定时器
      scanToken:'',//拿到扫码scanToken
      userNumber:'',//用户账号
      userNumberPassword:'',//用户密码
      userPhone:'',//用户手机号
      userGetSlit:'',//用户验证码
      saltCode:'',//盐值
      qrcode:'',//二维码
      timeMScoen:'',//分钟秒数时间
      urlArr:[],//路由数组
      urlIndex:0,//url下标
    };
  },
  mounted() {
    if (this.$route.query.flag == 1) {
      this.status = "saologin";
      this.getScanToken()
    }
  },
  created(){
    this.urlArr = this.$store.state.routePath;
    this.urlIndex = this.urlArr.findIndex((value)=>{
      return value.url == '/login'
    })
  },
  methods: {
    //点击logo跳转到首页
    toIndex(){
      this.$router.push({
        path:'/index'
      })
    },
    //倒计时三分钟切换二维码
    tabqrCode(){
      let time = 180;
      this.timers = setInterval(()=>{
        if(time>=0){
          let m = Math.floor(time/60)>10?Math.floor(time/60):'0'+Math.floor(time/60);  
          let s = Math.floor(time%60)>10?Math.floor(time%60):'0'+Math.floor(time%60);
          --time
          this.timeMScoen = m+':'+s;
        }else{
          clearInterval(this.timers)
          this.$message({
            showClose: true,
            message: '二维码时间过期，请重新获取',
            type: 'warning'
          });
          this.getScanToken()
        } 
      },1000)
      
    },
    //调用扫码接口认证用户
    getScanLogin(){
      let data = {
        scanToken:this.scanToken
      };
      this.myTimer = setInterval(()=>{
        scanLogin(data).then(res=>{
          if(res.code == 200){
            clearInterval(this.myTimer)
            this.$message({
              showClose: true,
              message: '登陆成功',
              type: 'success'
          });
          clearInterval(this.myTimer)
          clearInterval(this.timers)
          this.$store.commit('login',res.result.domain)
          if(this.urlIndex == -1){
            this.$router.go(-1)
            this.$store.commit('removePath')
          }else{
            this.$router.push({
              path:this.urlArr[this.urlIndex-1].url
            })
            this.$store.commit('removePath')
          }
          }
        })
      },3000)
    },
    //扫码生成token接口
    getScanToken(){
      scanToken().then(res=>{
        this.scanToken = res.result.domain.scanToken;
        let canvas = document.getElementById('canvas');
        let code = 'tongliaoCode'+this.scanToken
        QRCode.toCanvas(canvas,code, { margin: 1 ,width:145,height:145},  (error)=> {
          if (error) console.error(error)
          this.tabqrCode()
          this.getScanLogin()
        })
      })
    },
    loginTab() {
      if (this.status === "saologin") {
        this.status = "pwdlogin";
        clearInterval(this.myTimer);
        clearInterval(this.timers);
        this.scaner = require("../../assets/img/scaner.png");
        this.qrscaner = require("../../assets/img/qrcodebg.png");
        this.lftext = "忘记密码？";
      } else {
        this.status = "saologin";
        this.getScanToken();
        this.scaner = require("../../assets/img/scaner1.png");
        this.qrscaner = require("../../assets/img/qrcodebg1.png");
        this.lftext = "短信或密码登录";
      }
    },
    mesLogin() {
      this.status = "msglogin";
      this.hasError = true;
      this.active = false;
    },
    keyLogin() {
      this.status = "pwdlogin";
      this.hasError = false;
      this.active = true;
    },
    //回车登录事件
    keyLog(e){
      if(e.keyCode == 13){
        this.login()
      }
    },
    //点击登录
    login() {
        if(this.hasError){
          //短信验证码登录
          this.messLogin()
        }else{
          //账号密码登录
          this.accountPasswordLogin()
        }
    },
    
    //获取验证码
    getCode() {
      this.getSlcode()
    },
    //短信验证码直接登录接口
    messLogin(){
      let data = {
        phone:this.userPhone,
        code:this.userGetSlit
      };
      
        messageLogin(data).then(res=>{
          if(res.code == 200){
            this.$store.commit('login',res.result.domain)
            //登陆成功进行跳转页面
            // this.$router.push({path:'/'})
            //返回到上一个页面
             if(this.urlIndex == -1){
              this.$router.go(-1)
            this.$store.commit('removePath')
            }else{
              this.$router.push({
                path:this.urlArr[this.urlIndex-1].url
              })
            this.$store.commit('removePath')
            }
            this.$message({
              showClose: true,
              message: '登陆成功',
              type: 'success'
            });
          }else{
            this.$message({
                showClose: true,
                message: res.message,
                type: 'error'
            });
          }
        })
    },
    //账号密码登录
    accountPasswordLogin(){
      let data = {
        phone:this.userNumber,
        password:this.userNumberPassword
      };
      accountLogin(data).then(res=>{
        if(res.code == 200){
          this.$store.commit('login',res.result.domain)
          //登陆成功进行跳转页面
          // this.$router.push({path:'/'})
          //返回到上一个页面
           if(this.urlIndex == -1){
            this.$router.go(-1)
            this.$store.commit('removePath')
          }else{
            this.$router.push({
              path:this.urlArr[this.urlIndex-1].url
            })
            this.$store.commit('removePath')
          }
          this.$message({
            showClose: true,
            message: '登陆成功',
            type: 'success'
          });
        }else if(res.code == 500){
          this.$message({
              showClose: true,
              message: res.message,
              type: 'error'
          });
        }
      })
    },
    //获取盐值
    getSlcode(){
      getSalt().then(res=>{
        if(res.code == 200){
          this.saltCode = res.result.domain.saltCode;
          this.sendCodeRequest()
        }
      })
    },
    //短信验证码
    sendCodeRequest(){
        let phone = this.userPhone;
        let timestamp = Date.parse(new Date());
        let messageKey = 'TongLiao&&567_NM_yingguoyingyuyuyan%%';
        let saltCode = this.saltCode;
        let sign = MD5.md5(phone+timestamp+messageKey+saltCode)
        let messageType  = 'login';
        let data = {phone,timestamp,sign,saltCode,messageType}
         //验证手机号
        var reg = /^1[3|4|5|7|8][0-9]{9}$/; //验证规则
        let usePhone = new RegExp(reg);
        let muPhone = usePhone.test(this.userPhone);
        if(muPhone){
            sendCode(data).then(res=>{
              if(res.code == 200){
                this.timeEnd()
              }else{
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
              }
            })
        }else{
          this.$message({
              showClose: true,
              message: '请输入正确的手机号',
              type: 'error'
          });
        }
    },
    //倒计时
    timeEnd(){
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }else{
        console.log(' ') 
      }
    },
    forgetCode() {
      if (this.status === "saologin") {
        this.lftext = "忘记密码？";
        this.status = "msglogin";
      } else {
        this.$router.push("/forgetcode");
      }
    },
    register() {
      clearInterval(this.myTimer);
      clearInterval(this.timers);
      this.$router.push("/register");
    },
  },
};
</script>
<style>
#app {
  height: 100%;
}
</style>
<style scoped lang="scss">
.loginPic{
  position: absolute;
  top: 0;
  left: 0;
  width: 142px;
  height: 90px;
  cursor: pointer;
}
.lf {
  cursor: pointer;
  padding-bottom: 20px;
  float: left;
  width: 50%;
  padding: 0 10px 15px 45px;
}
.lfa {
  cursor: pointer;
  padding-bottom: 10px;
  float: left;
  width: 50%;
  font-size: 12px;
  color: #666666;
  padding: 15px 0;
}
.rta {
  cursor: pointer;
  width: 50%;
  font-size: 12px;
  color: #666666;
  padding: 15px 0;
  float: right;
}
.loginimg {
  height: 82px;
}
.rt {
  cursor: pointer;
  padding-bottom: 20px;
  width: 50%;
  padding: 0 45px 15px 10px;
  float: right;
}
input {
  outline: none;
  height: 50px;
  border-bottom: 1px solid #cccccc;
  border-top: none;
  border-left: none;
  width: 80%;
  border-right: none;
}
.container {
  text-align: center;
  width: 100%;
  height: 100%;
  background: #000;
  overflow: hidden;
  background: url("../../assets/img/bg.png") center center no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.form {
  margin-top: 10%;
  margin-left: 50%;
  width: 300px;
  height: 360px;
}
.qrcodebg {
  width: 82px;
  height: 82px;
  float: right;
}
.wen {
  position: relative;
  font-size: 12px;
  top: -18px;
  left: 8px;
  text-align: left;
}
.scaner {
  width: 84px;
  height: auto;
  margin-top: 21px;
  margin-left: 134px;
}
.right {
  background: #ffffff;
  border-radius: 4px;
  width: 300px;
  height: 360px;
  margin-left: -150px;
}
.login-form {
  width: 350px;
  margin: 0 auto;
}
.green {
  font-weight: bold;
  color: #097638;
}
.btn {
  margin-top: 10px;
  color: #fff;
  width: 260px;
  height: 40px;
  background: #097638;
  border: none; 
  outline:none;
  filter: chroma(color=#000000);
  border-radius: 2px;
}
.saoimg {
  width: 30px;
  height: 30px;
}
.getMsg {
  position: relative;
  top: -50px;
  display: block;
  left: 177px;
  background: #fff;
  height: 48px;
  cursor: pointer;
  line-height: 50px;
  width: 108px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.content {
  height: 166px;
  .qrCode{
    position: relative;
    width:100%;
    height: 145px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .timeCode{
      position: absolute;
      right: 35px;
      color: rgb(9, 118, 56);
      font-size: 12px;
    }
    .canv{
      width: 145px;
      height: 145px;
    }
  }
}
input:focus {
  outline: none;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1000;

  font-weight: 400;
  font-size: 14px;
  color: #fff;
}
.wei {
  margin-left: 5%;
  width: 90%;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
}
</style>